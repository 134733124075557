import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ManagerGuard, AdminGuard, SuperAdminGuard } from './auth.guard';

const routes: Routes =[
  { path: '', component: HomeComponent },
  { path: 'login', loadChildren: '../app/pages/login/login.module#LoginModule'},
  { path: 'unsubscribe/:email', loadChildren: '../app/pages/unsubscribe/unsubscribe.module#UnsubscribeModule'},
  { path: 'perfil/:id/ativacao/:idAtivacao', loadChildren: '../app/pages/profile/profile.module#ProfileModule'},
  { path: 'memorial/:id', loadChildren: '../app/pages/profile/profile.module#ProfileModule'},
  { path: 'memorial/:id/:nome', loadChildren: '../app/pages/profile/profile.module#ProfileModule'},
  { path: 'painel/:id', loadChildren: '../app/pages/presentation/presentation.module#PresentationModule'},
  { path: 'painel/e/:mode/:id', loadChildren: '../app/pages/chapel-panel/chapel-panel.module#ChapelPanelModule'},
  { path: 'editar/:id', loadChildren: '../app/pages/deceased-manager/deceased-manager.module#DeceasedManagerModule', canActivate: [ManagerGuard]},
  { path: 'admin', loadChildren: '../app/pages/admin/admin.module#AdminModule', canActivate: [AdminGuard]},
  { path: 'users', loadChildren: '../app/pages/users/users.module#UsersModule', canActivate: [SuperAdminGuard]},
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  { path: 'administrador', redirectTo: 'admin', pathMatch: 'full' },
  { path: 'perfil/:id', redirectTo: 'memorial/:id', pathMatch: 'full' },
  { path: 'gestao/:id', redirectTo: 'editar/:id', pathMatch: 'full' },
  { path: '401', loadChildren: '../app/pages/unauthorized/unauthorized.module#UnauthorizedModule'},
  { path: '404', loadChildren: '../app/pages/blank/blank.module#BlankModule'},
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
  // { path: 'gestao', loadChildren: '../app/pages/deceased-manager/deceased-manager.module#DeceasedManagerModule', canActivate: [AuthGuard]},
  // { path: 'obituario', loadChildren: '../app/pages/obituary/obituary.module#ObituaryModule' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
