import { Injectable, EventEmitter } from "@angular/core";
import { SERVER_URL } from "./../../config";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { UtilService } from "../util/util.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  hasLoggedUser: boolean = false;
  issueLoggedUser = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router,
    public util: UtilService
  ) {}

  forgot(username: string) {
    return this.http
      .get(SERVER_URL + "/morada/user/forgotPassword?email=" + username)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(SERVER_URL + "/morada/user/login", {
        username: username,
        password: password,
      })
      .pipe(
        map((data) => {
          this.hasLoggedUser = true;
          localStorage.setItem("jwt", data.token);
          localStorage.setItem("loggedUserId", data.userId);
          this.issueLoggedUser.emit(this.hasLoggedUser);
          return data;
        })
      );
  }

  logout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("authToken");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("uuid");
    localStorage.removeItem("loggedUserId");
    this.hasLoggedUser = false;
    this.router.navigate(["/login"]);
  }

  public getToken(): string {
    return localStorage.getItem("jwt");
  }

  public getAuthorizationToken(): any {
    return { Authorization: "Bearer " + localStorage.getItem("jwt") };
  }
}
