import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Location, DatePipe } from "@angular/common";
import { AuthenticationService } from "app/services/authentication/authentication.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap";
import { UtilService } from "app/services/util/util.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { BelovedOneService } from "app/services/deceased/beloved-one.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [DatePipe],
})
export class NavbarComponent implements OnInit {
  @ViewChild("mdConfig") mdConfig: ModalDirective;
  @ViewChild("mdProfileAtivation") mdProfileAtivation: ModalDirective;
  @ViewChild("mdHowToWork") mdHowToWork: ModalDirective;
  @ViewChild("mdCreateConfirmation") mdCreateConfirmation: ModalDirective;
  @ViewChild("deceasedNotFoundModal") deceasedNotFoundModal: ModalDirective;
  @ViewChild("deceasedNotFoundConfirm") deceasedNotFoundConfirm: ModalDirective;

  private toggleButton: any;
  private sidebarVisible: boolean;
  deceasedForm: FormGroup;
  userForm: FormGroup;
  deceasedNotFoundForm: FormGroup;
  selectedDeceasedFromAPI: any;
  selectedDeceased: any;
  isLogged: boolean;
  configForm: FormGroup;
  loggedUser: any;
  hasError: boolean;
  saving: boolean;
  feedbackMessage: string;
  success: boolean;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  searchName: any;
  listUsers: any = [];
  loadingSearch: boolean = false;
  foward: boolean = true;
  step: number = 0;
  userListUpdate = new Subject<string>();
  cpfAtivationChange = new Subject<string>();
  birthdayDateExibition: any = null;
  deathDateExibition: any = null;
  funeralStartDateExibition: any = null;
  funeralEndDateExibition: any = null;
  burialStartDateExibition: any = null;
  burialEndDateExibition: any = null;
  selectedUser: any;
  submitted: boolean = false;
  showFeedbackAtivation: boolean = false;
  searchingCpf: boolean = false;
  cpfInUse: boolean = false;
  sendingEmailDeceasedNotFound: boolean = false;
  submittedDeceasedNotFound: boolean = false;

  constructor(
    public location: Location,
    private element: ElementRef,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    public util: UtilService,
    private datePipe: DatePipe,
    public belovedOneService: BelovedOneService,
    private router: Router
  ) {
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
    this.sidebarVisible = false;
    if (localStorage.getItem("jwt") != null) {
      this.isLogged = true;
      this.util.getLoggedUser().subscribe((user) => {
        this.loggedUser = user;
        this.configForm.patchValue({
          id: this.loggedUser.id,
          name: this.loggedUser.name,
          email: this.loggedUser.email,
        });
      });
    }

    this.authenticationService.issueLoggedUser.subscribe(
      (hasLoggeduser: boolean) => {
        this.util.getLoggedUser().subscribe((user) => {
          this.loggedUser = user;
          this.isLogged = hasLoggeduser;
          this.configForm.patchValue({
            id: this.loggedUser.id,
            name: this.loggedUser.name,
            email: this.loggedUser.email,
          });
        });
      }
    );

    this.deceasedNotFoundForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      deceasedName: [null, Validators.required],
      comments: [null, Validators.required],
    });

    this.configForm = this.formBuilder.group({
      id: null,
      name: ["", Validators.required],
      email: "",
      password: "",
      confirmPassword: "",
      admin: null,
    });

    this.deceasedForm = this.formBuilder.group({
      id: null,
      name: [null, Validators.required],
      relative: null,
      description: [""],
      birthdayDate: [null, Validators.required],
      deathDate: [null, Validators.required],
      funeralLocation: null,
      funeralCity: null,
      funeralState: null,
      funeralStartDate: null,
      funeralEndDate: null,
      funeralRoom: null,

      burialLocation: null,
      burialCity: null,
      burialState: null,
      burialStartDate: null,
      burialEndDate: null,

      cremation: false,

      activeTribute: false,
      profilePicture: "",
      coverPicture: "",
      cpf: null,
      imageArray: null,
      imageArrayCover: null,
      falecidoId: null,

      waitingActivation: true,
    });

    this.userForm = this.formBuilder.group({
      id: null,
      name: "",
      email: "",
      password: "",
      acceptsNotifications: true,
      acceptsInfos: true,
      acceptsPolicy: true
    });

    this.userListUpdate
      .pipe(debounceTime(900), distinctUntilChanged())
      .subscribe((value) => {
        this.searchProfile(value);
      });

    this.cpfAtivationChange
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((value) => {
        this.checkCpf(value);
      });
  }

  logout() {
    this.authenticationService.logout();
    this.isLogged = false;
    this.loggedUser = false;
    this.configForm.patchValue({
      id: "",
      name: "",
      email: "",
    });
  }

  showConfig() {
    this.mdConfig.show();
  }

  // showConfirmation() {
  //     this.mdCreateConfirmation.show();
  // }

  showAtivationProfile() {
    this.showFeedbackAtivation = false;
    this.step = 0;
    this.searchName = "";
    this.listUsers = [];
    this.mdProfileAtivation.show();
  }

  showHowToWork() {
    this.mdHowToWork.show();
  }

  saveConfig() {
    this.configForm.disable();
    this.hasError = false;
    this.success = false;
    this.saving = true;
    if (
      (this.configForm.getRawValue().password =
        !null && this.configForm.getRawValue().confirmPassword != null) &&
      this.configForm.getRawValue().password ==
        this.configForm.getRawValue().confirmPassword &&
      this.configForm.getRawValue().name != "" &&
      this.configForm.getRawValue().name != null
    ) {
      this.configForm.patchValue({ admin: this.loggedUser.admin });
      this.util
        .updateUser(this.configForm.getRawValue())
        .subscribe((response) => {
          this.saving = false;
          this.success = true;
          this.configForm.enable();
          this.feedbackMessage = "Dados alterados com sucesso!";
        });
    } else if (
      this.configForm.getRawValue().password !=
      this.configForm.getRawValue().confirmPassword
    ) {
      this.saving = false;
      this.success = false;
      this.hasError = true;
      this.configForm.enable();
      this.feedbackMessage = "As senhas não conferem!";
    } else if (
      this.configForm.getRawValue().name == null ||
      this.configForm.getRawValue().name == ""
    ) {
      this.hasError = true;
      this.success = false;
      this.saving = false;
      this.configForm.enable();
      this.feedbackMessage = "Preencha o nome!";
    }
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  isHome() {
    var titlee = this.location.prepareExternalUrl(this.location.path());

    if (titlee === "/home") {
      return true;
    } else {
      return false;
    }
  }
  isDocumentation() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee === "/documentation") {
      return true;
    } else {
      return false;
    }
  }

  searchProfile(name) {
    this.loadingSearch = true;
    this.util.searchProfileAPI(name).subscribe((response) => {
      this.loadingSearch = false;
      this.listUsers = response;
    });
  }

  changeDeceased(deceasedFromAPI: any) {
    this.selectedDeceased = null;

    let isCremation =
      deceasedFromAPI.falecidolocalcerimoniacremacao != null &&
      deceasedFromAPI.falecidolocalcerimoniacremacao != ""
        ? true
        : false;
    this.selectedDeceased = {
      name: deceasedFromAPI.falecidonome,
      description: deceasedFromAPI.falecidobiografia,
      birthdayDate: this.getValidDate(deceasedFromAPI.falecidodatanascimento),
      deathDate: this.getValidDate(deceasedFromAPI.falecidodataobito),
      funeralLocation: deceasedFromAPI.falecidolocalprimeirovelorio,
      funeralCity: deceasedFromAPI.falecidocidadelogradourolocalprimeirovelorio,
      funeralState:
        deceasedFromAPI.falecidoestadologradourolocalprimeirovelorio,
      funeralStartDate: this.getValidDate(
        deceasedFromAPI.falecidodatainicioprimeirovelorio
      ),
      funeralEndDate: this.getValidDate(
        deceasedFromAPI.falecidodatafimprimeirovelorio
      ),
      funeralRoom: deceasedFromAPI.falecidocapelalocalprimeirovelorio,

      burialLocation: isCremation
        ? deceasedFromAPI.falecidolocalcerimoniacremacao
        : deceasedFromAPI.falecidolocaldestinocortejo,
      burialCity: isCremation
        ? deceasedFromAPI.falecidocidadelogradourolocalcerimoniacremacao
        : deceasedFromAPI.falecidocidadelogradourolocaldestinocortejo,
      burialState: isCremation
        ? deceasedFromAPI.falecidoestadologradourolocalcerimoniacremacao
        : deceasedFromAPI.falecidoestadologradourolocaldestinocortejo,
      burialStartDate: isCremation
        ? this.getValidDate(deceasedFromAPI.falecidodatacerimoniacremacao)
        : this.getValidDate(deceasedFromAPI.falecidodatasepultamento),
      burialEndDate: isCremation
        ? this.getValidDate(deceasedFromAPI.falecidodatacerimoniacremacao)
        : this.getValidDate(deceasedFromAPI.falecidodatasepultamento),

      activeTribute: true,
      cremation: isCremation,
      cpf: deceasedFromAPI.falecidocpf,
      burialAddress: deceasedFromAPI.falecidolocaldestinocortejo,
      funeralAddress: deceasedFromAPI.falecidolocalprimeirovelorio,
      falecidoId: deceasedFromAPI.falecidoid,
    };

    this.selectedUser = {
      name: null,
      email: null,
      password: null,
      acceptsNotifications: true,
      acceptsInfos: true,
      acceptsPolicy: true,
    };

    this.loadingSearch = true;
    this.showFeedbackAtivation = false;
    this.belovedOneService
      .checkByFalecidoId(deceasedFromAPI.falecidoid)
      .subscribe((res: any) => {
        if (!res) this.goNextStep();
        else
          this.feedBackAtivationConfig(
            true,
            true,
            false,
            "O falecido selecionado já esta cadastrado."
          );
        this.loadingSearch = false;
      });
  }

  getValidDate(date) {
    let d = null;
    if (date != null && date != "") d = new Date(date);
    return d;
  }

  goNextStep() {
    let check = false;
    this.showFeedbackAtivation = false;
    this.submitted = true;
    if (this.step == 0 && this.selectedDeceased != null) {
      check = true;
    } else if (this.step == 1 && this.selectedDeceased != null) {
      if (
        this.selectedDeceased.cpf != null &&
        this.selectedDeceased.cpf != "" &&
        this.cpfInUse
      ) {
        check = false;
      } else if (
        this.selectedDeceased.name == null ||
        this.selectedDeceased.name == ""
      ) {
        check = false;
        this.feedBackAtivationConfig(true, true, false, "Nome inválido");
      } else {
        check = true;
      }
    } else if (this.step == 2) {
      if (
        this.selectedUser != null &&
        this.selectedUser.name != null &&
        this.selectedUser.name != "" &&
        this.selectedUser.email != null &&
        this.selectedUser.email != "" &&
        this.selectedUser.password != null &&
        this.selectedUser.password != "" &&
        this.selectedUser.acceptsPolicy == true
      ) {
        this.userForm.patchValue({
          name: this.selectedUser.name,
          email: this.selectedUser.email,
          password: this.selectedUser.password,
          acceptsNotifications: this.selectedUser.acceptsNotifications,
          acceptsInfos: this.selectedUser.acceptsInfos,
          acceptsPolicy: this.selectedUser.acceptsPolicy,
        });
        this.getDeceased();
        this.saveDeceased();
      } else {
        check = false;
        this.feedBackAtivationConfig(
          true,
          true,
          false,
          "Preencha os campos obrigatórios."
        );
      }
    }

    if (check && this.step < 2) {
      this.foward = true;
      this.step = this.step + 1;
    }
  }

  saveDeceased() {
    this.deceasedForm.patchValue({ waitingActivation: true });
    this.deceasedForm.disable();
    this.userForm.disable();
    this.belovedOneService
      .ativationProfile(this.deceasedForm.getRawValue())
      .subscribe(
        (data: any) => {
          if (data && data.id > 0) {
            this.selectedDeceased = null;
            this.submitted = false;
            this.saving = false;
            this.success = true;
            this.deceasedForm.reset();
            this.deceasedForm.enable();
            this.userForm.reset();
            this.userForm.enable();
            this.mdProfileAtivation.hide();
            this.mdCreateConfirmation.show();
          } else
            this.feedBackAtivationConfig(
              true,
              true,
              false,
              data.errorMessage || "O CPF informado já está em uso"
            );
        },
        (error) => {
          this.deceasedForm.enable();
          this.userForm.enable();
          this.feedBackAtivationConfig(
            true,
            true,
            false,
            "Não foi possível cadastrar. Tente novamente!"
          );
        }
      );
  }

  // doLogin(userName, password, userId) {
  //   this.authenticationService
  //     .login(this.selectedUser.email, this.selectedUser.password)
  //     .subscribe((data: any) => {
  //       this.util.getLoggedUser().subscribe((data: any) => {});
  //     });
  // }

  goBackToStep() {
    this.showFeedbackAtivation = false;
    if (this.step >= 1) {
      this.submitted = false;
      this.foward = false;
      this.step = this.step - 1;
    }
  }

  getDeceased() {
    if (this.selectedDeceased.birthdayDate != null) {
      this.birthdayDateExibition = {
        year: Number(
          this.datePipe.transform(this.selectedDeceased.birthdayDate, "yyyy")
        ),
        month: Number(
          this.datePipe.transform(this.selectedDeceased.birthdayDate, "MM")
        ),
        day: Number(
          this.datePipe.transform(this.selectedDeceased.birthdayDate, "dd")
        ),
      };
    }
    if (this.selectedDeceased.deathDate != null) {
      this.deathDateExibition = {
        year: Number(
          this.datePipe.transform(this.selectedDeceased.deathDate, "yyyy")
        ),
        month: Number(
          this.datePipe.transform(this.selectedDeceased.deathDate, "MM")
        ),
        day: Number(
          this.datePipe.transform(this.selectedDeceased.deathDate, "dd")
        ),
      };
    }
    if (this.selectedDeceased.funeralStartDate != null) {
      this.funeralStartDateExibition = {
        year: Number(
          this.datePipe.transform(
            this.selectedDeceased.funeralStartDate,
            "yyyy"
          )
        ),
        month: Number(
          this.datePipe.transform(this.selectedDeceased.funeralStartDate, "MM")
        ),
        day: Number(
          this.datePipe.transform(this.selectedDeceased.funeralStartDate, "dd")
        ),
      };
    }
    if (this.selectedDeceased.funeralEndDate != null) {
      this.funeralEndDateExibition = {
        year: Number(
          this.datePipe.transform(this.selectedDeceased.funeralEndDate, "yyyy")
        ),
        month: Number(
          this.datePipe.transform(this.selectedDeceased.funeralEndDate, "MM")
        ),
        day: Number(
          this.datePipe.transform(this.selectedDeceased.funeralEndDate, "dd")
        ),
      };
    }
    if (this.selectedDeceased.burialStartDate != null) {
      this.burialStartDateExibition = {
        year: Number(
          this.datePipe.transform(this.selectedDeceased.burialStartDate, "yyyy")
        ),
        month: Number(
          this.datePipe.transform(this.selectedDeceased.burialStartDate, "MM")
        ),
        day: Number(
          this.datePipe.transform(this.selectedDeceased.burialStartDate, "dd")
        ),
      };
    }
    if (this.selectedDeceased.burialEndDate != null) {
      this.burialEndDateExibition = {
        year: Number(
          this.datePipe.transform(this.selectedDeceased.burialEndDate, "yyyy")
        ),
        month: Number(
          this.datePipe.transform(this.selectedDeceased.burialEndDate, "MM")
        ),
        day: Number(
          this.datePipe.transform(this.selectedDeceased.burialEndDate, "dd")
        ),
      };
    }

    this.deceasedForm.patchValue({
      name: this.toTitleCase(this.selectedDeceased.name),
      relative: this.userForm.getRawValue(),
      description: this.selectedDeceased.description,
      birthdayDate: this.selectedDeceased.birthdayDate,
      deathDate: this.selectedDeceased.deathDate,
      funeralLocation: this.selectedDeceased.funeralLocation,
      funeralCity: this.selectedDeceased.funeralCity,
      funeralState: this.selectedDeceased.funeralState,
      funeralStartDate: this.selectedDeceased.funeralStartDate,
      funeralEndDate: this.selectedDeceased.funeralEndDate,
      funeralRoom: this.selectedDeceased.funeralRoom,

      burialLocation: this.selectedDeceased.burialLocation,
      burialCity: this.selectedDeceased.burialCity,
      burialState: this.selectedDeceased.burialState,
      burialStartDate: this.selectedDeceased.burialStartDate,
      burialEndDate: this.selectedDeceased.burialEndDate,

      cremation: this.selectedDeceased.cremation,

      activeTribute: this.selectedDeceased.activeTribute,
      profilePicture: this.selectedDeceased.profilePicture,
      coverPicture: this.selectedDeceased.coverPicture,
      cpf: this.selectedDeceased.cpf,
      imageArray: null,
      imageArrayCover: null,
      falecidoId: this.selectedDeceased.falecidoId,
      active: false,
    });
  }

  toTitleCase(name) {
    name = name.toLowerCase().split(" ");
    let final = [];
    let disconsider = ["da", "de", "do", "das", "dos", "des", "e"];
    for (let word of name) {
      if (!disconsider.includes(word)) {
        final.push(word.charAt(0).toUpperCase() + word.slice(1));
      } else {
        final.push(word);
      }
    }
    return final.join(" ");
  }

  feedBackAtivationConfig(showFeedbackAtivation, hide, isSucces, message) {
    this.showFeedbackAtivation = showFeedbackAtivation;
    if (!isSucces) this.hasError = true;
    if (isSucces) this.success = true;
    this.feedbackMessage = message;
    if (hide) {
      setTimeout(function () {
        this.showFeedbackAtivation = false;
      }, 2000);
    }
  }

  isValid(control) {
    if (this.selectedDeceased != null) {
      let v = this.selectedDeceased[control];
      return v != null && v != "" ? true : false;
    } else {
      return false;
    }
  }

  checkCpf(cpf) {
    this.cpfInUse = false;
    this.showFeedbackAtivation = false;
    if (cpf) {
      this.searchingCpf = true;
      this.showFeedbackAtivation = false;
      this.belovedOneService.checkCpf(cpf).subscribe((res: any) => {
        if (res) {
          this.cpfInUse = true;
          this.feedBackAtivationConfig(
            true,
            true,
            false,
            "O CPF informado já está cadastrado"
          );
        } else {
          this.cpfInUse = false;
        }
        this.searchingCpf = false;
      });
    }
  }

  showDeceasedNotFoundModal() {
    this.mdProfileAtivation.hide();
    setTimeout(() => {
      this.deceasedNotFoundModal.show();
    }, 500);
  }

  sendDeceasedNotFoundEmail() {
    this.submittedDeceasedNotFound = true;
    this.sendingEmailDeceasedNotFound = true;
    if (this.deceasedNotFoundForm.invalid) {
      this.sendingEmailDeceasedNotFound = false;
      return;
    }
    this.deceasedNotFoundForm.disable();
    this.util
      .sendDeceasedNotFountEmail(this.deceasedNotFoundForm.getRawValue())
      .subscribe(
        (res: any) => {
          this.submittedDeceasedNotFound = false;
          this.sendingEmailDeceasedNotFound = false;
          this.deceasedNotFoundModal.hide();
          this.deceasedNotFoundForm.reset();
          this.deceasedNotFoundForm.enable();
          this.deceasedNotFoundConfirm.show();
        },
        (err) => {
          this.submittedDeceasedNotFound = false;
          this.deceasedNotFoundModal.hide();
          this.sendingEmailDeceasedNotFound = false;
        }
      );
  }

  sendAdmin(): void {
    this.router.navigateByUrl("/admin");
  }

  users(): void {
    this.router.navigateByUrl("/users");
  }

  isMobileAgent() {
    const agent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        agent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        agent.substr(0, 4)
      )
    );
  }
}