import { Injectable } from "@angular/core";
import { SERVER_URL } from "./../../config";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  loggedUser: any;
  visibleHF: boolean = true;

  constructor(private http: HttpClient, private route: ActivatedRoute) {}

  getLoggedUser() {
    return this.http
      .get(
        SERVER_URL + "/morada/user/getLoggedUser",
        this.setAuthorizationHeaderToken()
      )
      .pipe(
        map((data) => {
          return (this.loggedUser = data);
        })
      );
  }

  getUserLogged() {
    return this.http
      .get(
        SERVER_URL + "/morada/user/getLoggedUser",
        this.setAuthorizationHeaderToken()
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  setAuthorizationHeaderToken() {
    return {
      headers: new HttpHeaders().set(
        "Authorization",
        "Bearer " + localStorage.getItem("jwt")
      ),
    };
  }

  updateUser(userObject) {
    return this.http
      .put(
        SERVER_URL + "/morada/user/update/",
        userObject,
        this.setAuthorizationHeaderToken()
      )
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  uploadFile(file) {
    return this.http
      .post(
        SERVER_URL + "/morada/file/uploadFile",
        file,
        this.setAuthorizationHeaderToken()
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  hasLoggedUser() {
    return localStorage.getItem("jwt") != null ? true : false;
  }

  isAdmin() {
    return localStorage.getItem("jwt") != null &&
      this.loggedUser != null &&
      this.loggedUser.admin
      ? true
      : false;
  }

  isSuperAdmin() {
    return localStorage.getItem("jwt") != null &&
      this.loggedUser != null &&
      this.loggedUser.superAdmin
      ? true
      : false;
  }

  isManager() {
    return localStorage.getItem("jwt") != null &&
      this.loggedUser != null &&
      this.loggedUser.manager
      ? true
      : false;
  }

  showHF() {
    this.visibleHF = true;
  }

  hideHF() {
    this.visibleHF = false;
  }

  searchProfileAPI(name) {
    const token =
      "jmZfMsxXfTEOwRuAi3O7S0zP7wHJ0tYXW1uA6MC66wnG0uEwkjrgiENGhaF8VRY3";
    return this.http
      .get(
        "https://apiweb.moradadapaz.com.br/morada/Falecidos/getFalecidosMemorial?nome=" +
          name +
          "&access_token=" +
          token
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  apiAtivacao(falecidoId) {
    const token =
      "jmZfMsxXfTEOwRuAi3O7S0zP7wHJ0tYXW1uA6MC66wnG0uEwkjrgiENGhaF8VRY3";
    return this.http
      .get(
        "https://apiweb.moradadapaz.com.br/morada/Falecidos/signFalecidoMemorial?falecidoid=" +
          falecidoId +
          "&access_token=" +
          token
      )
      .subscribe((data) => {
        return data;
      });
  }

  sendConfirmationEmail(email, deceseasedName, deceasedId) {
    let httpParams: HttpParams = new HttpParams()
      .append("email", email)
      .append("name", deceseasedName)
      .append("deceasedId", deceasedId);
    return this.http
      .get(SERVER_URL + "/morada/deceased/sendEmailConfirmation", {
        params: httpParams,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  sendDeceasedNotFountEmail(email) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/sendDeceasedNotFountEmail", email)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  sendOpinionEmail(email) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/sendOpinionEmail", email)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  urlToBase64(url) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/urlToBase64", url, {
        responseType: "arraybuffer",
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  bufferToBase64(buf) {
    var binstr = Array.prototype.map
      .call(buf, function (ch) {
        return String.fromCharCode(ch);
      })
      .join("");
    return btoa(binstr);
  }

  unsubscribeEmail(unsubscribeRequest) {
    return this.http
      .post(SERVER_URL + "/morada/user/unsubscribeEmail", unsubscribeRequest)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
