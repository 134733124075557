import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from "rxjs/operators";
import { UtilService } from "app/services/util/util.service";
import { BelovedOneService } from "app/services/deceased/beloved-one.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor (private router: Router) { }
    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('jwt')) {
            return true;
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}

@Injectable()
export class AdminGuard implements CanActivate {
    constructor (
        private router: Router,
        private util: UtilService,
    ) {}
    
    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.util.hasLoggedUser()) {
            if (this.util.isAdmin() || this.util.isSuperAdmin()) {
                return true;
            } else {
                this.router.navigate(['/401']);
                return false;
            }
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}

@Injectable()
export class SuperAdminGuard implements CanActivate {
    constructor (
        private router: Router,
        private util: UtilService,
    ) {}
    
    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.util.hasLoggedUser()) {
            if (this.util.isSuperAdmin()) {
                return true;
            } else {
                this.router.navigate(['/401']);
                return false;
            }
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}

@Injectable()
export class ManagerGuard implements CanActivate {
    constructor (
        private router: Router,
        private util: UtilService,
        private deceasedService: BelovedOneService,
    ) {}
    
    canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.util.hasLoggedUser()) {
            return this.deceasedService.getRelative(route.paramMap.get('id'))
            .pipe(
                map((data: any) => {
                    if (this.util.isAdmin() || this.util.isSuperAdmin() || (
                        this.util.isManager() && data.id == localStorage.loggedUserId
                    )) {
                        return true;
                    } else {
                        this.router.navigate(['/401']);
                        return false;
                    }
                })
            );
            
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}