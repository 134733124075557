import { UtilService } from 'app/services/util/util.service';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Page } from 'app/models/page';
import { ActivatedRoute, Router } from '@angular/router';
import { BelovedOneService } from 'app/services/deceased/beloved-one.service';
import { FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };
    loading: boolean = false;
    loadingRecentHonored: boolean = false;
    page = new Page();
    itens = [];
    listRecent = [];
    focus;
    focus1;
    cardsNumber = [1, 1, 1, 1];
    cardsNumberMemory = [1, 1, 1, 1, 1, 1]
    filterForm: any  = {};
    dateFilter: any;
    dateOption: any;
    showFilter: boolean = false;
    hasError: boolean = false;
    hasClickedSearch: boolean = false;
    startDateFilter: any;
    endDateFilter: any;
    locaisVelorio = ['Morada da Paz São José (Natal/RN)', 'Morada da Paz Emaús (Natal/RN)', 'Morada da Paz Zona Norte (Natal/RN)'];
    states = [{label: 'Todos',value:''},{label:'Rio Grande do Norte',value:'RN'},{label:'Pernambuco',value:'PE'},{label: 'Paraíba',value:'PB'}];
    showDateFilter: boolean = false;
    loadingProducts: boolean = false;
    productList: any = [];
    filterTagDate: any;
    filterTagLocation: any;
    filterTagState: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public belovedOneService: BelovedOneService,
        private formBuilder: FormBuilder,
        private util: UtilService,
        private _renderer2: Renderer2,
        @Inject(DOCUMENT) private _document: Document,
    ) {
        
        this.page.pageNumber = 1;
        this.page.size = 8;
        // this.page.totalElements = 100;
        this.filterForm = {
            text: "",
            state: {},
            startDate: null,
            endDate: null,
            funeralLocation: "",
            burialLocation: null,
            dateType: null
        }
        this.dateFilter = [{ display: "Hoje", value: "today" }, { display: "Últimos 7 dias", value: "last7" },
        { display: "Últimos 15 dias", value: "last15" }, { display: "Últimos 30 dias", value: "last30" }, { display: "Informar Data", value: "personal-select" }];

        this.dateOption = [{ display: "Data de Óbito", value: "deathDate" }, { display: "Data do Velório", value: "burialDate" },
        { display: "Data de Nascimento", value: "birthdayDate" }];
 
    }

    ngOnInit() {
        this.onPageChange();
        this.listRecentHonored();

        let scriptHuggy = this._renderer2.createElement('script');
        scriptHuggy.text = `var $_Huggy = { defaultCountry: '+55', uuid: '436cd2aa-ce0c-4e5a-b8b9-cd525f8f3990' , company: '325478' }; (function(i,s,o,g,r,a,m){ i[r]={context:{id:'5a3971a7d2146c246940b2a321f77142'}};a=o;o=s.createElement(o); o.async=1;o.src=g;m=s.getElementsByTagName(a)[0];m.parentNode.insertBefore(o,m); })(window,document,'script','https://js.huggy.chat/widget.min.js','pwz');`;
        this._renderer2.appendChild(this._document.body, scriptHuggy);
    }

    toTitleCase(name) {
        name = name.toLowerCase().split(" ");
        let final = [];
        let disconsider = ["da", "de", "do", "das", "dos", "des", "e"];
        for (let word of name) {
          if (!disconsider.includes(word)) {
            final.push(word.charAt(0).toUpperCase() + word.slice(1));
          } else {
            final.push(word);
          }
        }
        return final.join(" ");
    }

    nameSlug(name) {
        name = name.toLowerCase().split(" ");
        let slug = name[0].replace(/[^a-zA-Z0-9]/g, "") + "-" + name[name.length - 1].replace(/[^a-zA-Z0-9]/g, "");
        return slug;
    }

    onPageChange() {
        this.loading = true;
        let paginationNgb = {
            pageNumber: (this.page.pageNumber - 1),
            size: this.page.size,
            name: this.filterForm.text,
            funeralLocation: this.filterForm.funeralLocation,
            state: ((this.filterForm.state != null) ? this.filterForm.state.value : ""),
            dateType: this.filterForm.dateType,
            startDate: this.filterForm.startDate,
            endDate: this.filterForm.endDate,
            customized: true
        }
        this.belovedOneService.list(paginationNgb).subscribe((data: any) => {
            this.page.totalElements = data.page.totalElements;
            this.itens = []
            if (data.data != null && data.data.length > 0) this.itens = data.data;
            for (let deceased of this.itens) {
                deceased.name = this.toTitleCase(deceased.name);
            }
            this.loading = false;
        }, err => {
            this.loading = false;
        });
    }

    selectDateOption(){
        if(this.filterForm.dateType == 'Informar Data') {
            this.showDateFilter = true;
        }else{
            this.showDateFilter = false;
        }
    }

    formatTwoNumbers(n) {
        n = n.toString();
        n = n.length == 1 ? '0' + n : n;
        return n;
    }

    search(hasClickedSearch) {
        this.filterTagState = null;
        if (this.filterForm.state != null) this.filterTagState = this.filterForm.state.label;
        this.filterTagLocation = this.filterForm.funeralLocation;
        this.filterTagDate = this.filterForm.dateType;
        if (this.filterTagDate == 'Informar Data') this.filterTagDate = this.formatTwoNumbers(this.startDateFilter.day) + "/" + this.formatTwoNumbers(this.startDateFilter.month) + "/" + this.startDateFilter.year;
        this.hasError = false;

        if (this.filterForm.dateType != null) {
            if (this.filterForm.dateType == 'Hoje') {
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                this.filterForm.startDate = today;
                today = new Date();
                today.setHours(23, 59, 59, 999);
                this.filterForm.endDate = today;
            } else if (this.filterForm.dateType == 'Últimos 7 dias') {
                var today = new Date()
                let priorDate = new Date();
                priorDate.setDate(today.getDate() - 7);
                priorDate.setHours(0, 0, 0, 0);
                this.filterForm.startDate = priorDate;
                today = new Date();
                today.setHours(23, 59, 59, 999);
                this.filterForm.endDate = today;

            } else if (this.filterForm.dateType == 'Últimos 15 dias') {
                var today = new Date()
                let priorDate = new Date();
                priorDate.setDate(today.getDate() - 15);
                priorDate.setHours(0, 0, 0, 0);
                this.filterForm.startDate = priorDate;
                today = new Date();
                today.setHours(23, 59, 59, 999);
                this.filterForm.endDate = today;

            } else if (this.filterForm.dateType == 'Últimos 30 dias') {
                var today = new Date()
                let priorDate = new Date();
                priorDate.setDate(today.getDate() - 30);
                priorDate.setHours(0, 0, 0, 0);
                this.filterForm.startDate = priorDate;
                today = new Date();
                today.setHours(23, 59, 59, 999);
                this.filterForm.endDate = today;

            } else if (this.filterForm.dateType == 'Informar Data') {
                if (this.startDateFilter == null) {
                    this.hasError = true;
                    return;
                } else {
                    let startDate = new Date(this.startDateFilter.year + '-' + this.transformStringDate(this.startDateFilter.month, this.startDateFilter.day) + 'T12:00:00Z');
                    startDate.setHours(0, 0, 0, 0);
                    this.filterForm.startDate = startDate;
                    let endDate = new Date(this.startDateFilter.year + '-' + this.transformStringDate(this.startDateFilter.month, this.startDateFilter.day) + 'T12:00:00Z');
                    endDate.setHours(23, 59, 59, 999);
                    this.filterForm.endDate = endDate;
                }
            }

            // let startDate = new Date(this.startDateFilter.year + '-' + this.transformStringDate(this.startDateFilter.month, this.startDateFilter.day) + 'T12:00:00Z');
            // let endDate = new Date(this.endDateFilter.year + '-' + this.transformStringDate(this.endDateFilter.month, this.endDateFilter.day) + 'T12:00:00Z');
            // if (startDate.getTime() > endDate.getTime()) {
            //     this.hasError = true;
            //     return;
            // }
            // this.filterForm.startDate = startDate;
            // this.filterForm.endDate = endDate;
        }
        this.onPageChange();

    }


    clearFilterTag(option) {
        if(option == 'state'){
            this.filterForm.state = null;
            this.filterTagState = '';
        }
        
        if(option == 'date'){
            this.filterForm.dateType = null;
            this.filterForm.startDate = null;
            this.filterForm.endDate = null;
            this.startDateFilter = null;
            this.filterTagDate = '';
        }

        if(option == 'location'){
            this.filterForm.funeralLocation = null;
            this.filterTagLocation = '';
        }

        this.onPageChange();
    }

    clearFilter() {
        this.filterForm = {
            text: "",
            state: null,
            startDate: null,
            endDate: null,
            funeralLocation: "",
            burialLocation: null,
            dateType: null
        }
        this.hasError = false;
        this.startDateFilter = null;
        this.endDateFilter = null;
        this.showDateFilter = false;
    }

    removeFilters(){
        this.clearFilter();
        this.filterTagDate = null;
        this.filterTagLocation = null;
        this.filterTagState = null;

        this.search(true);
        
    }

    showFilterOption() {
        this.showFilter = !this.showFilter;
        // if (!this.showFilter) this.clearFilter();
    }

    transformStringDate(month, day) {
        month = month.toString()
        day = day.toString()

        if (month.length == 1) {
            month = '0' + month
        }
        if (day.length == 1) {
            day = '0' + day
        }
        return month + '-' + day
    }

    listRecentHonored() {
        this.loadingRecentHonored = true;
        this.belovedOneService.listRecentHonored().subscribe((data: any) => {
            this.listRecent = data;
            for (let recent of this.listRecent) {
                recent.deceasedName = this.toTitleCase(recent.deceasedName);
            }
            this.loadingRecentHonored = false;
        }, err => {
            this.loadingRecentHonored = false;
        });
    }

    redirectTo(url){
        window.open(url);
    }


}

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return "";
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return {year: toInteger(dateParts[0]), month: null, day: null};
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return {year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null};
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return {year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0])};
            }
        }   
        return null;
    }

    format(date: NgbDateStruct): string {
        let stringDate: string = ""; 
        if(date) {
            stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
            stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
            stringDate += date.year;
        }
        return stringDate;
    }
}
