import {
  Component,
  OnInit,
  Inject,
  Renderer,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/operator/filter";
import { DOCUMENT } from "@angular/platform-browser";
import {  Location } from "@angular/common";
import { NavbarComponent } from "./shared/navbar/navbar.component";

declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  isLogged: boolean;

  @ViewChild(NavbarComponent) navbar: NavbarComponent;

  constructor(
    private renderer: Renderer,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef,
    public location: Location
  ) {
    if (localStorage.getItem("jwt")) this.isLogged = true;
  }

  ngOnInit() {
    var navbar: HTMLElement = this.document.getElementById("main-nav");
    var image: HTMLImageElement = this.document.getElementById("imageid");

    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (window.outerWidth > 991) {
          window.document.children[0].scrollTop = 0;
        } else {
          window.document.activeElement.scrollTop = 0;
        }
        this.navbar.sidebarClose();
      });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag("config", "UA-119198125-8", {
          page_path: event.urlAfterRedirects,
        });
        gtag("config", "G-X24WM17BP2", {
          page_path: event.urlAfterRedirects,
        });
      }
    });
    this.renderer.listenGlobal("window", "scroll", (event) => {
      const number = window.scrollY;
      if (number > 50 || window.pageYOffset > 50) {
        navbar.classList.remove("navbar-transparent");
        image.src = "../../../assets/img/logo-morada-da-memoria-green.png";
      } else {
        navbar.classList.add("navbar-transparent");
        image.src = "../../../assets/img/logo-morada-da-memoria-white.png";
      }
    });
    var ua = window.navigator.userAgent;
    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      var version = parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
    if (version) {
      var body = document.getElementsByTagName("body")[0];
      body.classList.add("ie-background");
    }
  }

  ngAfterViewInit() {
    var navbar: HTMLElement = this.document.getElementById("main-nav");
    var image: HTMLImageElement = this.document.getElementById("imageid");

    this.renderer.listenGlobal("window", "scroll", (event) => {
      const number = window.scrollY;
      if (number > 50 || window.pageYOffset > 50) {
        navbar.classList.remove("navbar-transparent");
        image.src = "../../../assets/img/logo-morada-da-memoria-green.png";
      } else {
        navbar.classList.add("navbar-transparent");
        image.src = "../../../assets/img/logo-morada-da-memoria-white.png";
      }
    });
  }

  removeFooter() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1, 7);
    if (titlee === "painel" || titlee === "login" || titlee === "capela" || titlee === "404" || titlee === "401") {
      return false;
    } else {
      return true;
    }
  }

  removeNavbar() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1, 7);
    if (titlee === "painel" || titlee === "capela") {
      return false;
    } else {
      return true;
    }
  }
}
