import { Injectable } from "@angular/core";
import { SERVER_URL } from "../../config";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "../authentication/authentication.service";
import { UtilService } from "../util/util.service";
import { Page } from "models/page";
import { PagedData } from "models/page-data";

@Injectable({
  providedIn: "root",
})
export class BelovedOneService {
  authorization: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private util: UtilService,
  ) {
    this.authorization = this.authenticationService.getAuthorizationToken();
  }

  getDeceased(id) {
    return this.http.get(SERVER_URL + "/morada/deceased/" + id).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getPublicDeceased(id) {
    return this.http.get(SERVER_URL + "/morada/deceased/public/" + id).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getRelative(deceasedId,) {
    return this.http.get(SERVER_URL + "/morada/deceased/" + deceasedId + "/relative", this.util.setAuthorizationHeaderToken()).pipe(
      map((data) => {
        return data;
      })
    );
  }

  isProfileOwner(deceasedId, userId) {
    this.getRelative(deceasedId)
      .subscribe((data: any) => {
        return data.id == userId ? true : false;
      });
  }
  
  checkCpf(cpf) {
    return this.http.get(SERVER_URL + "/morada/deceased/checkCPF/" + cpf).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkByFalecidoId(falecidoId) {
    return this.http
      .get(SERVER_URL + "/morada/deceased/checkByFalecidoId/" + falecidoId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  list(page) {
    let httpParams: HttpParams = new HttpParams()
      .append("start", (page.size * page.pageNumber).toString())
      .append("length", page.size)
      .append("name", page.name != null ? page.name : "")
      .append(
        "funeralLocation",
        page.funeralLocation != null ? page.funeralLocation : ""
      )
      .append("state", page.state != null ? page.state : "")
      .append("dateType", page.dateType != null ? page.dateType : "")
      .append(
        "startDate",
        page.startDate != null ? page.startDate.getTime() : null
      )
      .append("endDate", page.endDate != null ? page.endDate.getTime() : null)
      .append("customized", page.customized);

    return this.http
      .get(SERVER_URL + "/morada/deceased/list", { params: httpParams })
      .pipe(
        map((data) => {
          return this.getPagedData(page, data);
          // return data;
        })
      );
  }

  listAll(page) {
    let httpParams: HttpParams = new HttpParams()
      .append("start", (page.size * page.pageNumber).toString())
      .append("length", page.size)
      .append("name", page.name != null ? page.name : "")
      .append(
        "funeralLocation",
        page.funeralLocation != null ? page.funeralLocation : ""
      )
      .append("state", page.state != null ? page.state : "")
      .append("dateType", page.dateType != null ? page.dateType : "")
      .append(
        "startDate",
        page.startDate != null ? page.startDate.getTime() : null
      )
      .append("endDate", page.endDate != null ? page.endDate.getTime() : null)
      .append("customized", page.customized);

    return this.http
      .get(SERVER_URL + "/morada/deceased/listAll", { params: httpParams })
      .pipe(
        map((data) => {
          return this.getPagedData(page, data);
          // return data;
        })
      );
  }

  listRecentHonored() {
    return this.http
      .get(SERVER_URL + "/morada/deceased/listRecentHonored")
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  listByRelative(relativeId) {
    return this.http
      .get(SERVER_URL + "/morada/deceased/relative/" + relativeId + "/list", this.util.setAuthorizationHeaderToken())
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  listMemoryByDeceased(params, deceasedId) {
    return this.http
      .get(SERVER_URL + "/morada/deceased/" + deceasedId + "/memory/list", {
        params: params,
      })
      .pipe(
        map((data) => {
          var size = 0,
            key;
          for (key in data) {
            if (data.hasOwnProperty(key)) size++;
          }
          if (size > 0) {
            return data;
          }
        })
      );
  }

  insertMemory(memory) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/memory/insert", memory)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateMemory(memory) {
    return this.http.post(SERVER_URL + "/morada/deceased/admin/", memory).pipe(
      map((data) => {
        return data;
      })
    );
  }

  reportMemory(memoryId) {
    return this.http
      .get(SERVER_URL + "/morada/deceased/sendReportEmail/" + memoryId)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  deleteDeceased(deceasedId) {
    return this.http
      .delete(SERVER_URL + "/morada/deceased/admin/" + deceasedId, {
        headers: this.authorization,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateDeceased(deceased) {
    return this.http
      .put(SERVER_URL + "/morada/deceased/admin/", deceased, {
        headers: this.authorization,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  insertDeceased(deceased) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/admin/", deceased, {
        headers: this.authorization,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  ativationProfile(deceased) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/ativationProfile/", deceased, {
        headers: this.authorization,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  activeProfileByEmailRedirect(deceasedId) {
    return this.http
      .post(
        SERVER_URL +
          "/morada/deceased/activeProfileByEmailRedirect/" +
          deceasedId,
        null
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  private getPagedData(page: Page, data: any): PagedData<any> {
    const pagedData = new PagedData<any>();
    page.totalElements = data.recordsTotal;
    page.totalPages = page.totalElements / page.size;
    if (data.data.list !== undefined) {
      pagedData.data = data.data.list;
    }
    pagedData.page = page;
    return pagedData;
  }

  /***********************
   * DEFAULT_COVER_IMAGE *
   ***********************/
  listDefaultCoverImage(category) {
    let queryParam = "";
    if (category) queryParam = "?category=" + category;
    return this.http
      .get(SERVER_URL + "/morada/deceased/listDefaultCoverImage" + queryParam)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  listDefaultCoverImageCategoryList() {
    return this.http
      .get(SERVER_URL + "/morada/deceased/listDefaultCoverImageCategory")
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  changeEnableDeceased(deceased) {
    return this.http
      .post(SERVER_URL + "/morada/deceased/changeDisplayOption", deceased, this.util.setAuthorizationHeaderToken())
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  /**********
   * EVENT *
   **********/
  listEventByDeceased(deceasedId) {
    return this.http
      .get(SERVER_URL + "/morada/deceased/" + deceasedId + "/event/list")
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
