import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilService } from 'app/services/util/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/services/authentication/authentication.service';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @ViewChild("mdConfig") mdConfig: ModalDirective;
    @ViewChild("mdServices24h") mdServices24h: ModalDirective;

    isLogged: boolean;
    loggedUser: any;
    configForm: FormGroup;
    hasError: boolean;
    success: boolean;
    saving: boolean;
    feedbackMessage: string;

    today:number;

    constructor(private authenticationService: AuthenticationService, private formBuilder: FormBuilder, public util: UtilService) {
        if(localStorage.getItem('jwt') != null){
            this.isLogged = true;
            this.util.getLoggedUser().subscribe(user => {
                this.loggedUser = user
                this.configForm.patchValue({
                    id: this.loggedUser.id,
                    name: this.loggedUser.name,
                    email: this.loggedUser.email
                })
            })
        }

        this.configForm = this.formBuilder.group({
            id: null,
            name: ['', Validators.required],
            email: '',
            password: '',
            confirmPassword: '',
            admin: null
        })

        this.today = Date.now();
    }

    logout() {
        this.authenticationService.logout();
        this.isLogged = false;
    }

    showConfig() {
        this.mdConfig.show();
    }

    showServices24h() {
        this.mdServices24h.show();
    }

    saveConfig() {
        this.configForm.disable();
        this.hasError = false;
        this.success = false;
        this.saving = true;
          if ((this.configForm.getRawValue().password =! null && this.configForm.getRawValue().confirmPassword != null) && 
          (this.configForm.getRawValue().password == this.configForm.getRawValue().confirmPassword) && this.configForm.getRawValue().name != '' && this.configForm.getRawValue().name != null ) {
              this.configForm.patchValue({admin: this.loggedUser.admin})
              this.util.updateUser(this.configForm.getRawValue()).subscribe(response => {
                  this.saving = false;
                  this.success = true;
                  this.configForm.enable();
                  this.feedbackMessage = 'Dados alterados com sucesso!'
              })
          } else if(this.configForm.getRawValue().password != this.configForm.getRawValue().confirmPassword){
        this.saving = false;
        this.success = false;
        this.hasError = true;
        this.configForm.enable();
        this.feedbackMessage = 'As senhas não conferem!'
          } else if(this.configForm.getRawValue().name == null || this.configForm.getRawValue().name == ''){
              this.hasError = true;
              this.success = false;
              this.saving = false;
              this.configForm.enable();
              this.feedbackMessage = 'Preencha o nome!'
          }
      }

    ngOnInit() {}
}
